import {BasePage} from "../base-page/base-page";
import {UserService} from "../../../services/talos/user.service";
import {UtilsService} from "../../../services/utils.service";
import {Router} from "@angular/router";
import {ResourcesServices} from "../../../services/talos/resources.services";
import {QrSurveyService} from "./qr-survey.service";
import {OnInit} from "@angular/core";

export class BaseQrSurveyComponent extends BasePage implements OnInit{

    constructor(public qrSurveySrv: QrSurveyService, public userSrv: UserService, public utilsSrv: UtilsService, public router: Router, public resourcesSrv: ResourcesServices) {
        super(userSrv, utilsSrv);
    }

    ngOnInit() {
        this.goToNextStep();
    }

    navigateTo(link: string) {
        this.router.navigate([link]);
    }

    goToNextStep() {
        const a = this.qrSurveySrv.achievement;

        if(!a)
            return;

        if (a.completed) {

        } else {
            if (a.missionStarted) {
                if (a.quizCompleted) {
                    if (a.checkInCompleted) {
                        if (a.shareCompleted) {
                            this.navigateTo('qr-survey-coupon')
                        } else {
                            this.navigateTo('qr-survey-gps')
                        }
                    } else {
                        this.navigateTo('qr-survey-gps')
                    }
                } else {
                    this.navigateTo('qr-survey-detail')
                }
            }
        }
    }

    getResources(item: any, resourceType: number): string {
        return this.resourcesSrv.getResourcesBasic(item, resourceType);
    }

    getResourceUrl(itemId: string, itemTypeId: number, resourceTypeId: number): string {
        return this.resourcesSrv.getResourceUrl(itemId, itemTypeId, resourceTypeId);
    }
}