import {BasePage} from "../base-page/base-page";
import {Subject} from "rxjs/index";
import {UtilsService} from "../../../services/utils.service";
import {UserService} from "../../../services/talos/user.service";
import {StateDTO} from "../../../talosApi/models/StateDTO";
import {CartService} from "./cart.service";
import {OnDestroy} from "@angular/core";
import {TAConstants, TAValues} from "../../../talosApi/settings";
import ITEM_TYPES = TAConstants.ITEM_TYPES;
import {CartItem} from "./cart.presenter";
import {SalesOrderErrorCodes} from "../../../talosApi/api/SalesOrderApi";
import {isNil} from 'lodash';

export class CartBaseComponent extends BasePage implements OnDestroy {

    destroy: Subject<void> = new Subject();
    location: { longitude: number, latitude: number };
    iFrameUrl: string;
    states: Array<StateDTO> = [];

    constructor(public userSrv: UserService, public utilsSrv: UtilsService, public cartSrv: CartService) {
        super(userSrv, utilsSrv);
    }

    async getStates() {
        const states = await this.cartSrv.getStates().catch(err => {
        });
        if (states) {
            this.states = states;
        } else {
            this.states = [];
        }
    }

    getLocation(): void {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.location = {longitude: position.coords.longitude, latitude: position.coords.latitude};
                this.iFrameUrl = TAValues.CART_MAP_LINK.replace('$lat', String(this.location.latitude)).replace('$lon', String(this.location.longitude));
                // this.iFrameUrl = 'https://someurl.com/?lat=' + this.location.latitude + '&lon=' + this.location.longitude;
            });
        } else {
            logger.log("No support for geolocation")
        }
    }

    checkIfMustRemove(items: Array<CartItem>) {
        if (items) {
            items.forEach(i => {
                if (i.alert && i.alert.code == SalesOrderErrorCodes.ITEM_NOT_ACTIVE)
                    this.removeItemClicked(i);
            })
        }
    }

    removeItemClicked(item: CartItem) {
        logger.log(item);
        if (!isNil(item) && !isNil(item.itemId) && !isNil(item.salesOrderDetailsId)) {
            this.cartSrv.removeCartItem({
                salesOrderDetailsId: item.salesOrderDetailsId,
                itemId: item.itemId,
                itemTypeId: ITEM_TYPES.BRAND_PRODUCT,
                quantity: 0,
            });
        }
    }

    ngOnDestroy() {
        if (this.destroy) {
            this.destroy.next();
            this.destroy.complete();
        }
    }
}